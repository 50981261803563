import React from 'react';
import Highlight, { defaultProps } from 'prism-react-renderer';
import {
  LiveProvider, LiveEditor, LiveError, LivePreview,
} from 'react-live';
import { mdx } from '@mdx-js/react';
import 'prismjs/themes/prism-dark.css';

export default ({
  children = '', className = 'language-js', live, render,
}) => {
  const language = className.replace(/language-/, '');

  if (live) {
    return (
      <div style={{ marginTop: '40px', backgroundColor: 'black' }}>
        <LiveProvider
          code={children.trim()}
          transformCode={(code) => `/** @jsx mdx */${code}`}
          scope={{ mdx }}
        >
          <LivePreview />
          <LiveEditor />
          <LiveError />
        </LiveProvider>
      </div>
    );
  }

  if (render) {
    return (
      <div style={{ marginTop: '40px' }}>
        <LiveProvider code={children}>
          <LivePreview />
        </LiveProvider>
      </div>
    );
  }

  return (
    <Highlight {...defaultProps} code={children.trim()} language={language}>
      {({
        className, style, tokens, getLineProps, getTokenProps,
      }) => (
        <pre
          className={className}
          style={{
            ...style, padding: 20, textShadow: '0px 1px #000', border: 'none', borderRadius: 11,
          }}
        >
          {tokens.map((line, i) => (
            <div {...getLineProps({ line, key: i })}>
              {line.map((token, key) => (
                <span {...getTokenProps({ token, key })} />
              ))}
            </div>
          ))}
        </pre>
      )}
    </Highlight>
  );
};
