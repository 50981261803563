/* eslint-disable react/prop-types */
import React from 'react';
import { graphql, Link } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { css } from '@emotion/core';
import Button from '@material-ui/core/Button';
import { MDXProvider } from '@mdx-js/react';
import Bio from '../components/bio';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { rhythm, scale } from '../utils/typography';
import colors from '../utils/colors';
import CodeBlock from '../components/CodeBlock';


const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.mdx;
  const siteTitle = data.site.siteMetadata.title;
  const { slug } = post.fields;
  const { previous, next } = pageContext;
  const components = {
    code: CodeBlock,
  };
  const prodUrl = 'https://hassenchaieb.com' //No location prop in server side
  const discussUrl = `https://mobile.twitter.com/search?q=${encodeURIComponent(
    `${prodUrl}${slug}`,
  )}`;
  const imageUrl = post.frontmatter.image ? prodUrl+''+post.frontmatter.image.childImageSharp.original.src : undefined;
  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        image={imageUrl}
      />
      <article>
        <header>
          <h1
            style={{
              marginTop: rhythm(1),
              marginBottom: 0,
            }}
          >
            {post.frontmatter.title}
          </h1>
          <p
            style={{
              ...scale(-1 / 5),
              display: 'block',
              marginBottom: rhythm(1),
            }}
          >
            {post.frontmatter.date}
          </p>
        </header>
        <MDXProvider components={components}>
          <MDXRenderer>{post.body}</MDXRenderer>
        </MDXProvider>
        <p>
          <a href={discussUrl} target="_blank" rel="noopener noreferrer">
              Discuss on Twitter
          </a>
        </p>


        <form
          css={css`border:1px solid #ccc;padding:3px`}
          style={{
            boxShadow: '0px 1px 8px rgba(0,0,0,0.1)',
            background: colors.darkBlue,
            color: colors.white,
          }}
          action="https://tinyletter.com/hassen"
          method="post"
          target="popupwindow"
          onSubmit={() => { window.open('https://tinyletter.com/hassen', 'popupwindow', 'scrollbars=yes,width=800,height=600'); return true; }}
        >
          <div style={{
            display: 'flex',
            flexWrap: 'wrap',
            margin: 40,
            justifyContent: 'space-between',
          }}
          >
            <div style={{ flex: 1.2, paddingRight: 40 }}>
              <h3 style={{ marginTop: 0 }}>Join the newsletter</h3>
              <div
                style={{ color: 'rgb(173, 173, 173)', marginBottom: 8 }}
              >
                I write about cloud and software architectures.
              </div>
            </div>
            <div style={{ flex: 0.8 }}>
              <p>
                <input
                  type="text"
                  placeholder="Your email address"
                  css={css`
                    width:100%;
                    border-color: rgb(227, 227, 227);
                    border-radius: 4px;
                    color: rgb(0, 0, 0);
                    background: #fff;
                    font-size: 15px;
                    padding: 12px;
                    border: 1px solid #e3e3e3;
                    font-family: 'Lato', sans-serif;
                    flex: 1 0 auto;
                    line-height: 1.4;
                    margin: 0;
                    font-weight: 400;`}
                  name="email"
                />
                <Button
                  style={{ marginTop: 16 }}
                  color="primary"
                  variant="contained"
                  type="submit"
                >
                    Subscribe
                </Button>
                <input type="hidden" value="1" name="embed" />
              </p>
            </div>
          </div>


        </form>

        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
        <footer>
          <Bio />
        </footer>
      </article>

      <nav>
        <ul
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            listStyle: 'none',
            padding: 0,
          }}
        >
          <li>
            {previous && (
            <Link to={previous.fields.slug} rel="prev">
                  ←
              {' '}
              {previous.frontmatter.title}
            </Link>
            )}
          </li>
          <li>
            {next && (
            <Link to={next.fields.slug} rel="next">
              {next.frontmatter.title}
              {' '}
                  →
            </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        image {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
    }
  }
`;
